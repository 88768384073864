.el-vue-search-box-container {
  position: absolute;
  left: 16px;
  top: 16px;
  z-index: 10;
  width: 360px;
  height: 45px;
  background: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 2px 3px 3px 2px;
}
.el-vue-search-box-container input {
  height: 100%;
  width: 100%;
  line-height: 45px;
  letter-spacing: 0.5px;
  font-size: 14px;
  text-indent: 10px;
  box-sizing: border-box;
  border: none;
  outline: none;
}
